.header {
    background-color: #004aad;
    /* #f37e20;*/
    padding-left: calc(20px + 2vmin);
    padding-right: calc(20px + 2vmin);
    display: flex;
}
.header .signInNUp {
    padding-top: calc(10px + 2vmin);
    padding-bottom: calc(10px + 2vmin);
    color: white;
    margin-left: auto;
    font-weight: 550;
}
.header .signInNUp a, .header .signInNUp a:hover {
    color: white;
    cursor: pointer;
    text-decoration: none;
}
.header .signout {
    cursor: pointer;
}
.header .signInNUp a:hover, .header .signout:hover {
    text-decoration: underline;
}
