.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container {
  background-color: #dcdfe6;
  min-height: calc(100vh - 205px);
  background-color: #dcdfe6;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: rgb(31, 27, 27);
}
.continer-block {
 max-width: 1100px;
 width: 100%;
 margin-bottom: calc(10px + 2vmin);
}
.continer-block-full-width {
  width: 100%;
}
.grey-bg {
  background-color: #dcdfe6;
}
.footer {
  background-color: #004aad;
  min-height: calc(80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
h1 {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: calc(5px + 1vmin);
  margin-bottom: calc(20px + 1vmin);
  font-size: calc(12px + 1vmin);
  padding-bottom: 10px;
  background-color: white;
  border-bottom: 3px solid #c1209f;
  /* border-top: 3px solid #ff9800; */
  color: #004aad;
  text-align: left;
}

