#border-design {
    margin-top: 0px;
    height: 10px;
}
 
#border-design-left {
    background-color: #c1209f;
    display: inline-table;
    height: 10px;
    width: 51%;
    left: 0%;
    background-repeat: repeat-x;
    position: absolute;
    /* border-radius: 3px; */
}
 
#border-design-right {
    background-color: #ff9800;
    display: inline-table;
    width: 50%;
    right: 0%;
    height: 10px;
    background-repeat: repeat-x;
    position: absolute;
    /* border-radius: 3px; */
}