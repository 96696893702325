.nav {
    background-color: #cbcfd8;
    min-height: calc(20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: rgb(31, 27, 27);
  }
.MuiTab-wrapper {
    font-weight: 550;
}